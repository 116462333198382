import Head from 'next/head'
import { forwardRef, ReactNode } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import styled from 'styled-components'
// @mui
import { Box, BoxProps } from '@mui/material'
import useApp from '@/hooks/useApp'
import { SeoMetadata } from '@/services/page'
import find from 'lodash/find'
import merge from 'lodash/merge'
import { Thing, WithContext } from 'schema-dts'
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode
  title?: string
  description?: string
  jsonLd?: WithContext<Thing>
  metadataItems?: SeoMetadata[]
  openGraphImage?: {
    url?: string
    alt?: string
  }
}
const StyledVisuallyHiddenHeading = styled.h1`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
`

const Page = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      title: pageTitle,
      description: pageDescription,
      metadataItems = [],
      openGraphImage,
      jsonLd,
      ...other
    },
    ref
  ) => {
    const { locale } = useRouter()
    const {
      title: rootTitle,
      description: rootDescription,
      logo,
      seoMetadataItemsCollection,
      companyFavicon,
    } = useApp()

    const customTitle = find(metadataItems, { name: 'title' })?.content
    const customDescription = find(metadataItems, { name: 'description' })?.content
    const title = (customTitle || pageTitle)?.trim() || rootTitle
    const description = (customDescription || pageDescription)?.trim() || rootDescription
    const openGraphItems = [
      { name: 'og:type', content: 'website' },
      { name: 'og:url', content: typeof window !== 'undefined' ? window.location.href : '' },
      { name: 'og:title', content: title },
      {
        name: 'og:description',
        content: description,
      },
      { name: 'og:locale', content: locale },
      { name: 'og:image', content: openGraphImage?.url || logo },
      { name: 'og:image:alt', content: openGraphImage?.alt },
      { name: 'twitter:title', content: title },
      {
        name: 'twitter:description',
        content: description,
      },
      { name: 'twitter:image:src', content: openGraphImage?.url || logo },
    ].filter((item) => item.content)

    return (
      <>
        <Head>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
          {merge(openGraphItems, seoMetadataItemsCollection?.items ?? [], metadataItems)
            .filter((item) => item.name !== 'title' && item.name !== 'description')
            .map((metadataItem) => (
              <meta
                key={metadataItem.name}
                name={metadataItem.name}
                content={metadataItem.content ?? ''}
              />
            ))}
          {companyFavicon && (
            <>
              <link rel="apple-touch-icon" sizes="180x180" href={companyFavicon} />
              <link rel="icon" type="image/png" sizes="32x32" href={companyFavicon} />
              <link rel="icon" type="image/png" sizes="16x16" href={companyFavicon} />
            </>
          )}
        </Head>
        {jsonLd && (
          <Script
            id="json-ld-script"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          />
        )}
        <Box ref={ref} {...other}>
          {title && <StyledVisuallyHiddenHeading>{title}</StyledVisuallyHiddenHeading>}
          {children}
        </Box>
      </>
    )
  }
)

export default Page
